import { GridColDef, GridValidRowModel } from '@mui/x-data-grid-pro';
import { GridBlockType } from 'components/editor/shared/gridBlockType';
import { GridDeleteType, GridPositionChangedType, GridLayerChangedType, GridDimensionChangedType } from './saveHandlers';

export enum TableTypeIdentifier {
  TEXT_TABLE = 'text_table',
  PRICING_TABLE = 'pricing_table',
}
export enum ColumnTypeIdentifier {
  SUBTOTAL = 'Subtotal',
  PRICE = 'Price',
  MULTIPLIER = 'Multiplier',
  TEXT = 'Text',
}

type TableMetadataType = {
  tableType: TableTypeIdentifier;
  pricingDecimalPlaces: number;
};

export type TableColumnType = GridColDef & { columnType: ColumnTypeIdentifier };

export type TableRowType = GridValidRowModel;

export type TableType = {
  rows: TableRowType[];
  columns: TableColumnType[];
  metadata: TableMetadataType;
};

export type TableContentType = {
  rows: object[];
  columns: object[];
  metadata: TableMetadataType;
};

export type SaveTableContentType = TableContentType & GridDeleteType;

export type GridAddTableType = {
  type: GridBlockType;
  content: TableContentType;
  sectionId: string;
} & GridPositionChangedType &
  GridLayerChangedType &
  GridDimensionChangedType;
