import Big from 'big.js';
import { GridValidRowModel } from '@mui/x-data-grid-pro';
import { ColumnTypeIdentifier, TableColumnType } from '../../../../grid/reduxStore/table.types';
import { TableRowTypes } from '../../../../../../muiTheme/MuiDataGrid';

export default function calculateSubTotalsPerRow(
  rows: GridValidRowModel[],
  columns: TableColumnType[],
  precision = 5
): GridValidRowModel[] {
  const segregatedColumns = columns.reduce((acc, column) => {
    const { columnType } = column;

    if (!acc[columnType]) {
      acc[columnType] = [];
    }

    acc[columnType].push(column);
    return acc;
  }, {} as Record<ColumnTypeIdentifier, TableColumnType[]>);

  if (!segregatedColumns[ColumnTypeIdentifier.PRICE] || !segregatedColumns[ColumnTypeIdentifier.SUBTOTAL]) {
    return rows;
  }

  const priceColumn: TableColumnType = segregatedColumns[ColumnTypeIdentifier.PRICE][0];
  const subtotalColumn: TableColumnType = segregatedColumns[ColumnTypeIdentifier.SUBTOTAL][0];
  const multiplierColumns = segregatedColumns[ColumnTypeIdentifier.MULTIPLIER] || [];

  return rows.map((row) => {
    const priceStr = row[priceColumn.field];
    if (row.rowType !== TableRowTypes(true).BODY || !priceStr) return row;

    let subtotal = new Big(priceStr);

    multiplierColumns.forEach((multiplierCol) => {
      const multiplierValueStr = row[multiplierCol.field];
      const multiplierValue = multiplierValueStr !== '' ? new Big(multiplierValueStr) : new Big(1);
      subtotal = subtotal.times(multiplierValue);
    });

    const roundedSubtotal = Number(subtotal.toFixed(precision));

    return {
      ...row,
      [subtotalColumn.field]: roundedSubtotal,
    };
  });
}
