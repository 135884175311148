import { gridPixelSize, gridSnapDistanceInPx } from '../shared/gridConfig';
import { BlockMetadata, BlocksContentCollection, GridState } from '../grid/reduxStore/editorSlice';
import { getSignaturesMaxHeight } from '../SidePanel/Signatures/SignatureHelper';
import { SignatureBox } from 'services/repositories/interfaces/SignatureRepository';
import { BlockConfig } from './models/BlockConfig.model';
import { TrackedBlockCollection } from './models/TrackedBlockCollection.model';
import { Margin } from '../SidePanel/document-settings/DocumentDesignSettings/models/Margin.model';
import { TableRowTypes } from '../../../muiTheme/MuiDataGrid';
import { SectionContentCollectionType } from '../Sections/Section.model';
import { TableTypeIdentifier } from '../grid/reduxStore/table.types';
import { DEFAULT_DECIMAL_PLACES } from '../SidePanel/content/TableSettings/TableDefaultSettings';

export const maxImageWidthAllowedInPx = 500;
export const maxImageHeightAllowedInPx = 500;

export function mapRowType(rowTypeString: string): string {
  switch (rowTypeString) {
    case 'HEADER':
      return TableRowTypes().HEADER;
    case 'BODY':
      return TableRowTypes().BODY;
    case 'FOOTER':
      return TableRowTypes().FOOTER;
    case 'TOTAL':
      return TableRowTypes(true)['TOTAL'];
    default:
      return TableRowTypes().BODY;
  }
}

export function convertSectionsLoadDataToContentBlocks(sectionsData: SectionContentCollectionType): GridState {
  const defaultSection = {
    sections: {},
    blocksContent: {},
    blocksMetadata: [],
    blocksLayer: { greaterZIndexAvailable: 0, lowerZIndexAvailable: 0 },
    editorConfig: { currentManipulatedBlock: null, maxHeightPage: 0 },
  };
  return sectionsData.sections.reduce((reducedData, section) => {
    const sectionId = section.id;
    const sectionBlocksContent: Record<string, any> = {};
    const sectionBlocksMetadata: BlockMetadata[] = [];
    const sectionBlocksLayer = { greaterZIndexAvailable: 0, lowerZIndexAvailable: 0 };

    section.blocks.forEach((block) => {
      if (block.tableContent === undefined) {
        block.tableContent = {
          rows: [],
          columns: [],
          metadata: {
            tableType: TableTypeIdentifier.TEXT_TABLE,
            pricingDecimalPlaces: DEFAULT_DECIMAL_PLACES,
          },
        };
      }

      const convertedRows = block.tableContent.rows.map((row) => ({
        ...row,
        rowType: mapRowType(row.rowType.toUpperCase()),
      }));

      if (block.blockId) {
        sectionBlocksContent[block.blockId as string] = {
          content: block.htmlContent,
          contentTable: { ...block.tableContent, rows: convertedRows },
          type: block.type,
          blockConfig: {
            id: block.blockId,
            height: block.dimensions.height_px,
            width: block.dimensions.width_px,
            y: block.position.top_px,
            x: block.position.left_px,
            z: block.zIndex,
          },
        };
      }

      sectionBlocksMetadata.push({ id: block.blockId as string, type: block.type, imageAspectRatioLock: true });
      sectionBlocksLayer.greaterZIndexAvailable = Math.max(block.zIndex + 1, sectionBlocksLayer.greaterZIndexAvailable);
      sectionBlocksLayer.lowerZIndexAvailable = Math.min(block.zIndex - 1, sectionBlocksLayer.lowerZIndexAvailable);
    });

    reducedData.sections[sectionId] = {
      id: sectionId,
      order: section.order,
      blocksContent: sectionBlocksContent,
      blocksMetadata: sectionBlocksMetadata,
      blocksLayer: sectionBlocksLayer,
      editorConfig: { currentManipulatedBlock: null, maxHeightPage: 0 },
    };

    return reducedData;
  }, defaultSection);
}

export function getEditorMaxHeight(blocksContent: BlocksContentCollection | object, ...otherMaxValues: number[]) {
  let gridBlockMaxHeight = 0;
  if (blocksContent) {
    gridBlockMaxHeight = Object.entries(blocksContent).reduce((currentMax, blockContent) => {
      return Math.max(currentMax, blockContent[1].blockConfig.y + (blockContent[1].blockConfig.height || 0));
    }, 0);
  }

  return Math.max(gridBlockMaxHeight, ...otherMaxValues) + gridPixelSize * gridPixelSize;
}

export function roundToNearestMultipleOfGridSize(value: number) {
  return Math.round(value / gridPixelSize) * gridPixelSize;
}

export const calculateImageWidthAndHeight = (actualWidth: number, actualHeight: number) => {
  if (actualWidth <= maxImageWidthAllowedInPx && actualHeight <= maxImageHeightAllowedInPx) {
    return { calculatedWidth: actualWidth, calculatedHeight: actualHeight };
  }

  let calculatedWidth = actualWidth;
  let calculatedHeight = actualHeight;

  if (actualWidth > maxImageWidthAllowedInPx) {
    const aspectRatio = actualHeight / actualWidth;
    calculatedWidth = maxImageWidthAllowedInPx;
    calculatedHeight = calculatedWidth * aspectRatio;
  }

  if (calculatedHeight > maxImageHeightAllowedInPx) {
    const newAspectRatioAfterSettingHeight = calculatedWidth / calculatedHeight;
    calculatedHeight = maxImageHeightAllowedInPx;
    calculatedWidth = calculatedHeight * newAspectRatioAfterSettingHeight;
  }

  return { calculatedWidth, calculatedHeight };
};

const isElementVisibleFactory = (editorHeaderHeight: number) => {
  return (blockElement: Element | null) => {
    if (!blockElement) return false;
    const { top, bottom } = blockElement.getBoundingClientRect();

    return (top >= editorHeaderHeight && top <= window.innerHeight) || (bottom <= window.innerHeight && bottom >= editorHeaderHeight);
  };
};

export function setupTrackedBlocks(gridBlocks: BlocksContentCollection, signatures: SignatureBox[]): TrackedBlockCollection {
  const collection = {};
  const editorHeaderHeight = document.querySelector('.editor-header-wrapper')?.getBoundingClientRect().height || 0;
  const isElementVisible = isElementVisibleFactory(editorHeaderHeight);
  Object.values(gridBlocks).forEach((gridBlock) => {
    const blockElement = document.querySelector(`.block_${gridBlock.blockConfig.id}`);
    if (!isElementVisible(blockElement)) {
      return;
    }

    collection[gridBlock.blockConfig.id] = {
      height: gridBlock.blockConfig.height,
      width: gridBlock.blockConfig.width,
      x: gridBlock.blockConfig.x,
      y: gridBlock.blockConfig.y,
    };
  });

  signatures.forEach((signature) => {
    const blockElement = document.querySelector(`.signature_${signature.signatureBoxId}`);
    if (!isElementVisible(blockElement)) {
      return;
    }

    collection[signature.signatureBoxId] = {
      height: signature.properties.dimensions.height,
      width: signature.properties.dimensions.width,
      x: signature.properties.position.x,
      y: signature.properties.position.y,
    };
  });

  return collection;
}

export function calculatePageMaxHeight(gridBlocks: BlocksContentCollection, signatureBlocks?: SignatureBox[], activeBlock?: BlockConfig) {
  const pageMaxHeightBasedOnGridBlocks = getPageMaxHeightBasedOnGridBlocks(gridBlocks, activeBlock);
  const pageMaxHeightBasedOnSignatureBlocks = getSignaturesMaxHeight(signatureBlocks);
  if (pageMaxHeightBasedOnSignatureBlocks > pageMaxHeightBasedOnGridBlocks) {
    return pageMaxHeightBasedOnSignatureBlocks;
  }
  return pageMaxHeightBasedOnGridBlocks;
}

export function getPageMaxHeightBasedOnGridBlocks(gridBlocks: BlocksContentCollection, activeBlock?: BlockConfig) {
  let pageMaxHeight = 0;
  for (const key in gridBlocks) {
    let currentBlockPageHeight: number;

    /* gridBlocks parameter doesn't contain an updated state of a block that is currently being dragged,
    resized or having its content changed. So we rely on activeBlock being passed with the updated state. */
    if (activeBlock && key === activeBlock.id) {
      currentBlockPageHeight = activeBlock.height + activeBlock.y;
    } else {
      currentBlockPageHeight = gridBlocks[key].blockConfig.y + (gridBlocks[key].blockConfig.height || 0);
    }

    if (pageMaxHeight < currentBlockPageHeight) {
      pageMaxHeight = currentBlockPageHeight;
    }
  }
  return pageMaxHeight;
}

export function getBoundingPosition(currentBlock: BlockConfig, boundaries?: { height: number; width: number }) {
  if (!boundaries) return { x: null, y: null };
  const { width: blockWidth, x: blockX, y: blockY } = currentBlock;

  const { width: boundaryWidth } = boundaries;

  const blockRight = blockX + blockWidth;

  let reachedBoundaryX: number | null = null;
  let reachedBoundaryY: number | null = null;

  if (blockX < 0) {
    reachedBoundaryX = 0;
  } else if (blockRight > boundaryWidth) {
    reachedBoundaryX = boundaryWidth - blockWidth;
  }
  // Prevent the block from moving to the top of the page, but allow it to move over the bottom of the block.
  if (blockY < 0) reachedBoundaryY = 0;

  return { x: reachedBoundaryX, y: reachedBoundaryY };
}

function findSnapMatchesPosition(
  currentBlockPoints: number[],
  id: string,
  currentBlock: BlockConfig,
  trackedBlockPoints: number[],
  pointAdjustment: number[],
  fixedTrackedPoints: number[]
): number[] {
  const snapMatches: number[] = [];

  for (let i = 0; i < currentBlockPoints.length; i++) {
    // Check against moving blocks positions
    if (id !== currentBlock.id) {
      for (let j = 0; j < trackedBlockPoints.length; j++) {
        const distanceBetweenBlocks = currentBlockPoints[i] - trackedBlockPoints[j];

        if (distanceBetweenBlocks >= -gridSnapDistanceInPx && distanceBetweenBlocks <= gridSnapDistanceInPx) {
          snapMatches.push(trackedBlockPoints[j] - pointAdjustment[i]);
        }
      }
    }

    // Check against fixed positions
    for (let j = 0; j < fixedTrackedPoints.length; j++) {
      const distanceBetweenBlocks = currentBlockPoints[i] - fixedTrackedPoints[j];

      if (distanceBetweenBlocks >= -gridSnapDistanceInPx && distanceBetweenBlocks <= gridSnapDistanceInPx) {
        snapMatches.push(fixedTrackedPoints[j] - pointAdjustment[i]);
      }
    }
  }
  return snapMatches;
}

export function getSnapPosition(
  currentBlock: BlockConfig,
  trackedBlockCollection: TrackedBlockCollection,
  pageMaxWidthPx: number,
  margins: Margin
) {
  if (!currentBlock || !Object.keys(trackedBlockCollection).length) {
    return { x: null, y: null };
  }

  const possibleSnappingPositions: { xAxisMatchesPx: number[]; yAxisMatchesPx: number[] } = {
    xAxisMatchesPx: [],
    yAxisMatchesPx: [],
  };

  const currentBlockHeight = currentBlock.height;
  const currentBlockWidth = currentBlock.width;

  const draggedBlockBottom = currentBlock.y + currentBlockHeight;
  const draggedBlockRight = currentBlock.x + currentBlockWidth;

  const draggedBlockMiddleX = currentBlock.x + currentBlockWidth / 2;
  const draggedBlockMiddleY = currentBlock.y + currentBlockHeight / 2;

  const pageMiddleX = pageMaxWidthPx / 2;

  const currentBlockHalfWidth = currentBlock.width / 2;
  const currentBlockHalfHeight = currentBlock.height / 2;

  const marginRightXPx = pageMaxWidthPx - margins.right;

  const currentBlockXPoints: number[] = [currentBlock.x, draggedBlockRight, draggedBlockMiddleX];

  const currentBlockYPoints: number[] = [currentBlock.y, draggedBlockBottom, draggedBlockMiddleY];

  // We use these 2 arrays to adjust the resulting x and y values based on the currentBlock's
  // width and height during a snap action.
  const xAdjustment: number[] = [0, currentBlock.width, currentBlockHalfWidth];
  const yAdjustment: number[] = [0, currentBlock.height, currentBlockHalfHeight];

  const fixedTrackedXPoints = [pageMiddleX, margins.left, marginRightXPx];

  const fixedTrackedYPoints = [margins.top];

  for (const [id, trackedData] of Object.entries(trackedBlockCollection)) {
    const trackedBlockHeight = trackedData.height;
    const trackedBlockWidth = trackedData.width;

    const trackedBlockBottom = trackedData.y + trackedBlockHeight;
    const trackedBlockRight = trackedData.x + trackedBlockWidth;
    const trackedBlockMiddleX = trackedData.x + trackedBlockWidth / 2;
    const trackedBlockMiddleY = trackedData.y + trackedBlockHeight / 2;

    // The following arrays contain the dragged block and tracked block's X and Y points
    // for the 4 sides of each block and their middle points, as well as page middle points.
    const trackedBlockXPoints: number[] = [
      trackedData.x,
      trackedBlockRight,
      trackedBlockMiddleX,
      pageMiddleX,
      margins.left,
      marginRightXPx,
    ];

    const trackedBlockYPoints: number[] = [trackedData.y, trackedBlockBottom, trackedBlockMiddleY, margins.top];

    possibleSnappingPositions.xAxisMatchesPx = possibleSnappingPositions.xAxisMatchesPx.concat(
      findSnapMatchesPosition(currentBlockXPoints, id, currentBlock, trackedBlockXPoints, xAdjustment, fixedTrackedXPoints)
    );

    possibleSnappingPositions.yAxisMatchesPx = possibleSnappingPositions.yAxisMatchesPx.concat(
      findSnapMatchesPosition(currentBlockYPoints, id, currentBlock, trackedBlockYPoints, yAdjustment, fixedTrackedYPoints)
    );
  }

  // snap priority given to leftmost x and topmost y from each group
  const snapMatchX = possibleSnappingPositions.xAxisMatchesPx.length ? Math.min(...possibleSnappingPositions.xAxisMatchesPx) : null;
  const snapMatchY = possibleSnappingPositions.yAxisMatchesPx.length ? Math.min(...possibleSnappingPositions.yAxisMatchesPx) : null;

  return {
    x: snapMatchX,
    y: snapMatchY,
  };
}
