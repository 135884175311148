import { List } from 'antd';
import { Link } from 'react-router-dom';
import './styles.less';
import usePreviewUrl from '../../../editor/usePreviewUrl';
import { ReactNode, useEffect, useState } from 'react';
import { Document } from 'services/documents/entities/Document';
import { NotifyObject, listUndoObserver } from '../listUndoObserver';
import { DuplicateDocumentAction } from './listActionItems/DuplicateDocumentAction';
import { TrashDocumentAction } from './listActionItems/TrashDocumentAction';
import { BetaBadge } from '../beta-badge';
import { Chip, Divider } from '@mui/material';
import { useCurrentUser } from 'providers/UserProvider';
import { convertCreatedAtDateToUserTime, formatCreatedAtDate, isToday } from 'utils/dateHandlers';
import { Avatar } from 'components/avatar';
import { Tooltip } from 'components';
import { FeatureFlags } from 'utils/featureFlags';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { MoreDocumentAction } from './listActionItems/MoreDocumentAction';
import { getDocumentLink } from './getDocumentLink';

export type ListType = 'Documents' | 'Trash';

export type Props = {
  type: ListType;
  documentList: Document[];
  setDocumentList: (documents: Document[] | ((prev: Document[]) => Document[])) => Promise<void>;
  isLoading: boolean;
  listEmpty: ReactNode;
  currentPage: number;
  handleChangePage: (page: number) => void;
  action: {
    icon: ReactNode;
    message: string;
    tooltipText: string;
    onAction: (documentId: string) => Promise<void>;
    onUndo: (documentId: string) => Promise<void>;
  };
  refreshDocumentList: () => Promise<void>;
};

export function PipelineList({
  type,
  documentList,
  setDocumentList,
  listEmpty,
  isLoading,
  action,
  currentPage,
  handleChangePage,
  refreshDocumentList,
}: Props) {
  const [highlightItemId, setHighlightItemId] = useState('');
  const [hoveredItemId, setHoveredItemId] = useState<string | null>(null);
  const previewUrlPrefix = usePreviewUrl();
  const { data: currentUserInfo } = useCurrentUser();
  const evaluateFeatureFlag = useFeatureFlag([FeatureFlags.documentStatusesV3]);
  const hasV3DocumentStatusFlag = evaluateFeatureFlag['v3-document-statuses'];

  useEffect(() => {
    const observerId = listUndoObserver.subscribe({
      action: removeDocumentWhenUndoFromAnotherTab,
    });

    return () => {
      listUndoObserver.unsubscribe(observerId);
    };
  }, []);

  const removeDocumentWhenUndoFromAnotherTab = ({ listType, documentId }: NotifyObject) => {
    if (listType !== type) {
      setDocumentList((previousList) => previousList.filter((document) => document.id !== documentId));
    }
  };

  const handleOnListMouseEnter = (documentId: string) => {
    setHoveredItemId(documentId);

    if (!highlightItemId) {
      return;
    }
    setHighlightItemId('');
  };

  const handleOnListMouseLeave = () => {
    setHoveredItemId(null);
  };

  const viewNewlyDuplicatedDocument = (itemId: string) => {
    if (currentPage === 1) {
      const targetDocument = document.getElementById(itemId);
      if (targetDocument) {
        targetDocument.scrollIntoView();
      }
    } else {
      handleChangePage(1);
      scroll(0, 0);
    }
  };

  const showDateOrTime = (createdAtDate: string) => {
    const userTimezone = currentUserInfo.timezone || 'UTC';

    if (isToday(createdAtDate)) {
      return convertCreatedAtDateToUserTime(createdAtDate, userTimezone);
    } else {
      return formatCreatedAtDate(createdAtDate);
    }
  };

  const formatStatusToClassName = (status: string) => status.toLowerCase().replace(' ', '-');

  return (
    <List
      className="pipeline__list"
      loading={isLoading}
      dataSource={documentList}
      locale={{ emptyText: listEmpty }}
      renderItem={(item) => {
        const isEditorv3 = item.editorVersion === 3;
        let assignedUser = '';
        if (item.assigneeUserFirstName && item.assigneeUserLastName) {
          assignedUser = item.assigneeUserFirstName + ' ' + item.assigneeUserLastName;
        }

        const assignedUserProfileImage = assignedUser === currentUserInfo.fullName ? currentUserInfo.avatar : null;

        const assignedStandaloneContactFullName =
          item.prospectFirstName && item.prospectLastName && `${item.prospectFirstName} ${item.prospectLastName}`;

        const assignedProspect = item.companyName || assignedStandaloneContactFullName || '-';

        const tooltip = assignedUser;
        const isHovered = hoveredItemId === item.id;
        const documentLink = getDocumentLink({
          documentId: item.id,
          status: item.status,
          editorVersion: item.editorVersion,
          isLocked: item.isLocked,
          previewUrlPrefix,
        });

        return (
          <Link
            to={documentLink}
            key={item.id}
            id={item.id}
            data-testid={`pipelineItem${item.id}`}
            className={`document-version-${item.editorVersion}`}
          >
            <List.Item
              className={`${formatStatusToClassName(item.status)} ${highlightItemId === item.id ? ' item-highlight' : ''}`}
              onMouseEnter={() => handleOnListMouseEnter(item.id)}
              onMouseLeave={handleOnListMouseLeave}
              data-testid={`list-item-${item.id}`}
              id={`list-item-${item.id}`}
              onClick={(event) => event.stopPropagation()}
            >
              <List.Item.Meta
                title={
                  <div className="document-title-container">
                    {isEditorv3 ? <BetaBadge /> : null}
                    <div className="document-title">{item.title}</div>
                  </div>
                }
                description={assignedProspect}
              ></List.Item.Meta>
              <div className={`document-details ${isHovered && isEditorv3 ? 'hidden' : ''}`}>
                <div className="document-created-date">{showDateOrTime(item.created_at)}</div>
                <Tooltip placement={'bottom'} title={tooltip}>
                  <div className="assigned_user">
                    <Avatar
                      userFirstName={item.assigneeUserFirstName}
                      userLastName={item.assigneeUserLastName}
                      profileImage={assignedUserProfileImage}
                    />
                  </div>
                </Tooltip>
                <div className="document-status-container">
                  <Chip
                    label={item.status}
                    variant="filled"
                    className={`document-status-chip ${formatStatusToClassName(item.status)}`}
                    size="small"
                    sx={{
                      '& .MuiChip-label': {
                        paddingLeft: 0,
                        paddingRight: 0,
                      },
                    }}
                  />
                </div>
              </div>

              <div className={`document-actions ${isHovered ? '' : 'hidden'}`} data-testid="document-actions">
                {type === 'Documents' && isEditorv3 ? (
                  <DuplicateDocumentAction
                    documentForDuplicate={item}
                    setDocumentList={setDocumentList}
                    setHighlightItemId={setHighlightItemId}
                    viewNewlyDuplicatedDocument={viewNewlyDuplicatedDocument}
                  />
                ) : null}
                {isEditorv3 ? (
                  <TrashDocumentAction
                    listType={type}
                    documentList={documentList}
                    documentToTrash={item}
                    setDocumentList={setDocumentList}
                    action={action}
                    isLoading={isLoading}
                  />
                ) : null}

                {isEditorv3 && hasV3DocumentStatusFlag ? (
                  <MoreDocumentAction
                    documentStatus={item.status}
                    documentId={item.id}
                    documentTitle={item.title}
                    refreshDocumentList={refreshDocumentList}
                    setHoveredItemId={setHoveredItemId}
                  />
                ) : null}
              </div>
            </List.Item>
            <Divider className="document-divider" />
          </Link>
        );
      }}
    />
  );
}
