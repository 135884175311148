import { SidePanelContextColumnsPropsType, SidePanelProviderContext } from '../../SidePanelModelsProvider';
import { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { GridApiPro, GridColumnResizeParams } from '@mui/x-data-grid-pro';
import { SelectionContext } from '../../../../GridDndEditor/SelectedBlockInfoProvider';
import { useAppDispatch } from '../../../../grid/reduxStore/Store';
import {
  TableColumnType,
  TableRowType,
  TableType,
  TableTypeIdentifier,
  ColumnTypeIdentifier,
} from '../../../../grid/reduxStore/table.types';
import { useBlockContentChangedHandler } from '../../../../hooks/UseBlockContentChangedHandler';
import { defaultColumnMeta } from '../../../../GridDndEditor/Block/Table/variables';
import { useBlockDeletedHandler } from '../../../../hooks/UseBlockDeletedHandler';
import { BlockContent } from '../../../../grid/reduxStore/editorSlice';
import { TableColumnAddPositions } from '../types';
import { updateGridTableBlockState } from '../../../../grid/reduxStore/editorSlice';
import { useTableManipulation } from '../../../../GridDndEditor/Block/Table/useTableManipulation';
import { TableRowTypes } from '../../../../../../muiTheme/MuiDataGrid';
import { setActiveTableSettingsPanel, TableSettingsTypes } from '../../../../grid/reduxStore/blockStyleSettingsSlice';
import { openNotification } from 'components/notification';
import { useTranslation } from 'react-i18next';
const defaultAddedColumnInPx = 120;
type GetEmptyColumnType = {
  isDuplicated?: boolean;
};
export const getEmptyColumn = ({ isDuplicated = false }: GetEmptyColumnType): TableColumnType => {
  if (isDuplicated) {
    return { ...defaultColumnMeta, field: uuidv4() };
  } else {
    return { ...defaultColumnMeta, field: uuidv4(), width: defaultAddedColumnInPx };
  }
};

export const useTableColumnManipulation = () => {
  const { toggledTableSettingsPanel, setToggledTableSettingsPanel } = useContext(
    SidePanelProviderContext
  ) as SidePanelContextColumnsPropsType;
  const { selectedBlockIdByWrapper, selectedSectionId: sectionId } = useContext(SelectionContext);
  const gridBlockContentChangedHandler = useBlockContentChangedHandler();
  const gridBlockDeleteHandler = useBlockDeletedHandler();
  const blockContentChangedHandler = useBlockContentChangedHandler();
  const { getTableData, getBlockContent, updateBlockDimensionsWithTableDimensions } = useTableManipulation();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const updateTableState = (blockId: string, tableData: TableType) => {
    if (!sectionId) throw new Error('Section id cannot be empty');
    const payload = {
      sectionId: sectionId,
      blockId: blockId,
      contentTable: tableData,
    };

    dispatch(updateGridTableBlockState(payload));
  };

  const addColumnAtIndexPosition = (
    allColumns: TableColumnType[],
    newColumnData: TableColumnType,
    positionIndex: number
  ): TableColumnType[] => {
    const updatedColumns: TableColumnType[] = [];
    for (let index = 0; index < allColumns.length; index++) {
      if (positionIndex === index) {
        updatedColumns.push(newColumnData);
      }
      updatedColumns.push({ ...allColumns[index] });
    }

    if (positionIndex === updatedColumns.length) {
      updatedColumns.push(newColumnData);
    }

    return updatedColumns;
  };

  const deleteColumnFieldFromRows = (allRows: TableRowType[], columnFieldId: string): TableRowType[] => {
    return allRows.map((row) => {
      const rowToBeUpdated = { ...row };
      delete rowToBeUpdated[columnFieldId];
      return rowToBeUpdated;
    });
  };

  type DeleteColumnAtIndexPositionType = {
    updatedColumns: TableColumnType[];
    updatedRows: TableRowType[];
  };

  const deleteColumnAtIndexPosition = (
    allColumns: TableColumnType[],
    allRows: TableRowType[],
    positionIndex: number,
    isShowTotalEnabled: boolean
  ): DeleteColumnAtIndexPositionType => {
    const columnFieldId = allColumns[positionIndex].field;
    const isSubtotalColumn = allColumns[positionIndex].columnType === ColumnTypeIdentifier.SUBTOTAL;
    const updatedRows = deleteColumnFieldFromRows(allRows, columnFieldId);
    if (isSubtotalColumn && isShowTotalEnabled) {
      openNotification({
        type: 'warning',
        title: t('document.grid.table.errors.calculations_are_based_on_subtotals.title'),
        description: t('document.grid.table.errors.calculations_are_based_on_subtotals.desc'),
        testId: 'remove-subtotal-column-warning',
      });
    }
    const updatedColumns: TableColumnType[] = [];
    for (let index = 0; index < allColumns.length; index++) {
      if (index === positionIndex) continue;
      updatedColumns.push({ ...allColumns[index] });
    }

    return { updatedColumns, updatedRows };
  };

  const setColumnFocus = (columnField: string) => {
    if (!toggledTableSettingsPanel) return;

    const tableCallbackDetails = toggledTableSettingsPanel.tableApi.tableCallbackDetails;

    tableCallbackDetails.api.setColumnHeaderFocus(columnField);
    dispatch(setActiveTableSettingsPanel({ type: TableSettingsTypes.TABLE_COLUMNS }));
    setToggledTableSettingsPanel({
      tableApi: {
        selectedModel: tableCallbackDetails?.api.getColumnHeaderParams(columnField),
        tableCallbackDetails: tableCallbackDetails,
      },
    });
  };

  const handleColumnAdd = async (position: TableColumnAddPositions, newColumn: TableColumnType = getEmptyColumn({})) => {
    if (!toggledTableSettingsPanel || !selectedBlockIdByWrapper) return;

    if (!sectionId) throw new Error('Section id cannot be empty');
    const tableData = getTableData(selectedBlockIdByWrapper, sectionId) as TableType;

    const allColumns = tableData.columns;
    const allRows = [...tableData.rows];
    const footerRowElement = tableData.rows.find((row) => row.rowType === TableRowTypes().FOOTER);

    const columnSelectedModel = toggledTableSettingsPanel.tableApi.selectedModel;
    const selectedColumnIndex = allColumns.findIndex((column) => column.field === columnSelectedModel.field);
    const indexToPlaceNewColumn = selectedColumnIndex + position;

    const updatedColumns = addColumnAtIndexPosition(allColumns, newColumn, indexToPlaceNewColumn);

    const updatedRows = allRows.map((row) => {
      if (row.rowType === TableRowTypes().FOOTER && footerRowElement) {
        const firstColumnFooterData = footerRowElement[tableData.columns[1].field];
        return { ...row, [updatedColumns[1].field]: firstColumnFooterData };
      } else {
        return row;
      }
    });
    await blockContentChangedHandler(selectedBlockIdByWrapper, sectionId, { ...tableData, rows: updatedRows, columns: updatedColumns });

    await updateBlockDimensionsWithTableDimensions(
      selectedBlockIdByWrapper,
      sectionId,
      toggledTableSettingsPanel.tableApi.tableCallbackDetails.api
    );

    const newColumnField = updatedColumns[indexToPlaceNewColumn].field;
    setColumnFocus(newColumnField);
  };

  const getUpdatedRowsWithDuplicatedColumnField = (allRows: TableRowType[], columnFieldId: string, columnFieldToDuplicate: string) => {
    return allRows.map((row) => ({ ...row, [columnFieldId]: row[columnFieldToDuplicate] }));
  };

  const handleColumnDuplication = async () => {
    if (!toggledTableSettingsPanel || !selectedBlockIdByWrapper) return;
    if (!sectionId) throw new Error('Section id cannot be empty');
    const tableData = getTableData(selectedBlockIdByWrapper, sectionId) as TableType;

    const columnSelectedModel = toggledTableSettingsPanel.tableApi.selectedModel;
    const allRows = [...tableData.rows];
    const allColumns = [...tableData.columns];

    const newColumnDefaultData = getEmptyColumn({ isDuplicated: true });
    const columnFieldId = newColumnDefaultData.field;
    const selectedColumnIndex = allColumns.findIndex((column) => column.field === columnSelectedModel.field);
    const updatedColumns = addColumnAtIndexPosition(
      allColumns,
      { ...allColumns[selectedColumnIndex], ...newColumnDefaultData },
      selectedColumnIndex + TableColumnAddPositions.AFTER
    );

    const updatedRows = getUpdatedRowsWithDuplicatedColumnField(allRows, columnFieldId, columnSelectedModel.field);

    await blockContentChangedHandler(selectedBlockIdByWrapper, sectionId, { ...tableData, columns: updatedColumns, rows: updatedRows });

    await updateBlockDimensionsWithTableDimensions(
      selectedBlockIdByWrapper,
      sectionId,
      toggledTableSettingsPanel.tableApi.tableCallbackDetails.api
    );

    setColumnFocus(columnFieldId);
  };

  const deleteTableBlockAndHideTableSettingsPanel = async (blockId: string) => {
    if (!sectionId) throw new Error('Section id cannot be empty');
    const blockContent = getBlockContent(blockId, sectionId) as BlockContent;
    await gridBlockDeleteHandler(sectionId, blockId, blockContent);
    setToggledTableSettingsPanel(null);
  };

  const handleColumnDeletion = async () => {
    if (!toggledTableSettingsPanel || !selectedBlockIdByWrapper) return;
    if (!sectionId) throw new Error('Section id cannot be empty');
    const tableData = getTableData(selectedBlockIdByWrapper, sectionId) as TableType;
    const isShowTotalEnabled = tableData?.metadata?.tableType === TableTypeIdentifier.PRICING_TABLE;
    const columnSelectedModel = toggledTableSettingsPanel.tableApi.selectedModel;

    const allRows = [...tableData.rows];
    const footerRowElement = allRows.find((row) => row.rowType === TableRowTypes().FOOTER);
    const allColumns = [...tableData.columns];
    const selectedColumnIndex = allColumns.findIndex((column) => column.field === columnSelectedModel.field);

    const { updatedColumns, updatedRows } = deleteColumnAtIndexPosition(allColumns, allRows, selectedColumnIndex, isShowTotalEnabled);

    const isIdTheLastColumnLeft = updatedColumns.length === 1 && updatedColumns[0].field === 'id';
    if (isIdTheLastColumnLeft) {
      await deleteTableBlockAndHideTableSettingsPanel(selectedBlockIdByWrapper);
      return;
    }
    const updatedRowsWithFooter = updatedRows.map((row) => {
      if (row.rowType === TableRowTypes().FOOTER && footerRowElement) {
        const firstColumnFooterData = footerRowElement[tableData.columns[1].field];
        return { ...row, [updatedColumns[1].field]: firstColumnFooterData };
      } else {
        return row;
      }
    });

    await gridBlockContentChangedHandler(selectedBlockIdByWrapper, sectionId, {
      ...tableData,
      columns: updatedColumns,
      rows: updatedRowsWithFooter,
    });
    await updateBlockDimensionsWithTableDimensions(
      selectedBlockIdByWrapper,
      sectionId,
      toggledTableSettingsPanel.tableApi.tableCallbackDetails.api
    );

    const columnToTheLeft = selectedColumnIndex > 1 ? selectedColumnIndex - 1 : selectedColumnIndex;
    setColumnFocus(updatedColumns[columnToTheLeft].field);
  };

  const updateColumnWidthStoreState = (blockId: string, updatedColumnField: string, updatedColumnWidth: number) => {
    if (!sectionId) throw new Error('Section id cannot be empty');
    const tableData = getTableData(blockId, sectionId);
    if (!tableData) return;
    const updatedColumns = tableData.columns.map((column) => {
      if (column.field === updatedColumnField) {
        return { ...column, width: updatedColumnWidth };
      }
      return { ...column };
    });
    updateTableState(blockId, { ...tableData, columns: updatedColumns });
  };

  const handleColumnResize = (blockId: string, gridColumnResizeParams: GridColumnResizeParams) => {
    const resizedColumnField = gridColumnResizeParams.colDef.field;
    const resizedColumnNewWidth = gridColumnResizeParams.width;
    updateColumnWidthStoreState(blockId, resizedColumnField, resizedColumnNewWidth);
  };

  const handleColumnResizingStop = async (blockId: string, tableApi: GridApiPro) => {
    if (!sectionId) throw new Error('Section id cannot be empty');
    const tableData = getTableData(blockId, sectionId) as TableType;
    await blockContentChangedHandler(blockId, sectionId, { ...tableData });

    await updateBlockDimensionsWithTableDimensions(blockId, sectionId, tableApi);
  };

  return {
    handleColumnAdd,
    handleColumnDuplication,
    handleColumnDeletion,
    handleColumnResize,
    handleColumnResizingStop,
    updateColumnWidthStoreState,
  };
};
