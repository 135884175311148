import { TableContentType, TableType, TableTypeIdentifier } from '../../../grid/reduxStore/table.types';
import { rootStore } from '../../../grid/reduxStore/Store';
import { useBlockDimensionChangedHandlerWithoutUndoRedo } from '../../../hooks/UseBlockDimensionChangedHandler';
import { GridApiCommon, GridApiPro } from '@mui/x-data-grid-pro';
import { TableRowTypes } from '../../../../../muiTheme/MuiDataGrid';
import { setOfRowsForPricingTable } from './defaultData';
import { useBlockContentChangedHandler } from '../../../hooks/UseBlockContentChangedHandler';
import { useDispatch } from 'react-redux';
import { updateGridTableBlockState, updateGridDimensionConfig, BlockContent } from '../../../grid/reduxStore/editorSlice';

export const useTableManipulation = () => {
  const dispatch = useDispatch();
  const blockDimensionChangedHandlerWithoutUndoRedo = useBlockDimensionChangedHandlerWithoutUndoRedo();
  const blockContentChangedHandler = useBlockContentChangedHandler();
  const getBlockContent = (blockId: string, sectionId: string): BlockContent | null => {
    return { ...rootStore.getState().gridBlockReducer.sections[sectionId].blocksContent[blockId] };
  };

  const getTableData = (blockId: string, sectionId: string): TableType | undefined => {
    const blockContent = getBlockContent(blockId, sectionId);
    if (!blockContent) return undefined;
    return blockContent.contentTable;
  };

  const getTableUpdatedDimensions = (tableApi: GridApiPro | GridApiCommon) => {
    if (!tableApi || !tableApi.rootElementRef.current) return undefined;
    const visibleColumns = tableApi.getVisibleColumns();
    const columnsTotalWidth = visibleColumns.reduce((width, column) => width + (column.width ?? 0), 0);

    return {
      width: columnsTotalWidth,
      height: tableApi.rootElementRef.current.offsetHeight as number,
    };
  };

  const updateBlockDimensionsWithTableDimensions = async (blockId: string, sectionId: string, tableApi: GridApiPro | GridApiCommon) => {
    const dimensions = getTableUpdatedDimensions(tableApi);
    if (!dimensions) return;

    await blockDimensionChangedHandlerWithoutUndoRedo(sectionId, blockId, { widthPx: dimensions.width, heightPx: dimensions.height });
  };

  const setTableDecimalPlaces = async (blockId: string, sectionId: string, updatedDecimalPlaces: number) => {
    const tableData = getTableData(blockId, sectionId);
    if (!tableData) return;
    const { metadata } = tableData;
    const updatedTableData = {
      ...tableData,
      metadata: {
        ...metadata,
        pricingDecimalPlaces: updatedDecimalPlaces,
      },
    } as TableContentType;

    await blockContentChangedHandler(blockId, sectionId, updatedTableData);
  };

  const toggleTableType = (blockId: string, sectionId: string) => {
    const tableContentData = getTableData(blockId, sectionId);
    if (!tableContentData) return;

    const { metadata, rows, columns } = tableContentData;
    const isPricingActive = metadata?.tableType === TableTypeIdentifier.PRICING_TABLE;

    const updatedRowsData = isPricingActive
      ? rows.filter((row) => row.rowType !== TableRowTypes(true)['TOTAL'])
      : [...rows, ...setOfRowsForPricingTable];

    return {
      rows: updatedRowsData,
      columns,
      metadata: {
        ...metadata,
        tableType: isPricingActive ? TableTypeIdentifier.TEXT_TABLE : TableTypeIdentifier.PRICING_TABLE,
      },
    };
  };

  const updateTableBlockContentStoreState = (blockId: string, sectionId: string, tableData: TableType) => {
    const payload = {
      sectionId: sectionId,
      blockId: blockId,
      contentTable: tableData,
    };
    return dispatch(updateGridTableBlockState(payload));
  };

  //TODO:This might fix table block wrapper height calculation bug sp-1024
  const updateTableBlockDimensionsStoreState = (
    blockId: string,
    sectionId: string,
    { width, height }: { width: number; height: number }
  ) => {
    dispatch(updateGridDimensionConfig({ sectionId, blockId, width, height }));
  };

  return {
    getBlockContent,
    getTableData,
    getTableUpdatedDimensions,
    updateBlockDimensionsWithTableDimensions,
    toggleTableType,
    setTableDecimalPlaces,
    updateTableBlockContentStoreState,
    updateTableBlockDimensionsStoreState,
  };
};
